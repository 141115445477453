@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Rubik Medium;
    font-weight: 500;
    src: url('./assets/fonts/Rubik-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: Raleway Medium;
    font-weight: 500;
    src: url('./assets/fonts/Raleway-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: Raleway SemiBold;
    font-weight: 600;
    src: url('./assets/fonts/Raleway-SemiBold.ttf') format('truetype');
  }
  @font-face {
    font-family: ChivoMono Regular;
    font-weight: 400;
    src: url('./assets/fonts/ChivoMono-Regular.ttf') format('truetype');
  }
  body {
    @apply m-0 font-['Raleway_Medium'] text-lg;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  #root {
    @apply w-full h-full overflow-y-scroll xl:scrollbar relative;
  }
  h1 {
    @apply font-["Rubik_Medium"] text-afina-purple xl:text-7xl xl:leading-[5rem] text-4xl xl:text-left text-center xl:w-fit w-full
  }
  h3 {
    @apply font-["Rubik_Medium"] text-afina-purple text-2xl
  }
  h4 {
    @apply text-afina-purple
  }
  h5 {
    @apply font-medium text-xl text-afina-purple font-['Rubik_Medium']
  }
  .main-container a {
    @apply underline text-afina-purple
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #CCCCCC;
    border-radius: 100vh;
    border: 1px solid #e1e1e1;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #bbbbbb;
  }

  .story-background {
    background-image: url(../src/assets/coins-left.png), url(../src/assets/coins-right.png);
    background-position: bottom left, bottom right;
    background-repeat:no-repeat;
    background-size: 25%;
  }

  .main-background {
    background-image: linear-gradient(to right top, #d6f4da, #d5f7eb, #dcf9f8, #e8fafe, #f5fbff, #f4f8ff, #f4f5fe, #f6f2fc, #f1e7f9, #eedcf5, #ecd1ef, #ebc5e8);
  }
}
